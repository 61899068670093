import { VFC } from 'react'
import styled from 'styled-components'
import { RoundedCornersContainer } from './RoundedCornersContainer'
import { ExampleCardProps } from './types'
import { colorConfig, caseColor, mixins } from '@/utils/styleConfig'
import { breakpointUp } from '@/utils/breakpoints'

export const ExampleCard: VFC<ExampleCardProps> = ({ caseType, heading, subHeading }) => (
  <RoundedCornersContainer>
    <StyledExampleCard caseType={caseType}>
      <Heading>
        <SubHeading>{subHeading}</SubHeading>
        {heading}
      </Heading>
    </StyledExampleCard>
  </RoundedCornersContainer>
)

export const ExampleCardList = styled.ul`
  display: grid;
  gap: 1.6rem;

  ${breakpointUp('md')} {
    gap: 2rem 3rem;
    grid-template-columns: repeat(2, 1fr);
  }
  ${breakpointUp('lg')} {
    grid-template-columns: repeat(3, 1fr);
  }
`

const StyledExampleCard = styled.div<Partial<ExampleCardProps>>`
  background: linear-gradient(
    135deg,
    ${({ caseType }) => caseColor[caseType].gradation1},
    ${({ caseType }) => caseColor[caseType].gradation2}
  );
`

const Heading = styled.p`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 2.4rem;
  font-weight: bold;
  height: 9.4rem;
`

const SubHeading = styled.small`
  font-size: 1.6rem;
`
